<template>
  <div>
    <v-tabs
      class="aggregatedTabs"
      v-model="currentGroupIndex"
      background-color="transparent"
      mobile-breakpoint="0"
      height="62"
      show-arrows
      @change="loadAggregateData"
    >
      <v-tab
        v-for="(item, index) in aggregatedDataNames"
        class="px-10 font-medium"
        :key="index"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
    
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :custom-sort="customSort"
      class="elevation-0 borderNone tableBg borderAggregatedTable"
      outlined
      disable-pagination
      hide-default-footer
      :loader-height="2"
      :header-props="{ sortIcon: '$sortDownIcon' }"
      :mobile-breakpoint="0"
    >
      <template #no-data>
        <div class="d-flex">
          <span class="font-normal text-left">No data available</span>
        </div>
      </template>
  
      <template #item.name="{ item }">
        <v-btn
          class="px-1 justify-start"
          :class="activeClass(item)"
          text
          elevation="0"
          :ripple="false"
          @click="changeFilter(item)"
        >{{ item.name }}</v-btn>
      </template>
      
      <template #item.live="{ item }">
        <div
          v-if="currentGroupPropertyName === 'destination'"
          class="d-flex justify-center"
        >
          <v-btn
            v-if="selectDestinationSlugForLive !== item.destination.slug"
            class="px-4 not-disabled-color tableBodyText--text"
            outlined
            depressed
            min-width="54"
            @click="visibleLiveInput(item)"
          >
            <AnimationChangeLive :live="item.live" :maxConcurrency="getMaxConcurrency(item)"/>

            <span v-if="getMaxConcurrency(item)">/ {{ getMaxConcurrency(item) }}</span>
          </v-btn>
          
          <v-card flat class="d-flex" v-else v-click-outside="hideLiveInput">
            <InputNumberControl
              class="pr-4 minHeight36"
              v-model="selectMaxConcurrency"
              :min="0"
              autofocus
            />
            <ActionButton
              class="px-4"
              height="36"
              min-width="60"
              :loading="loadingStatus"
              @click="saveConcurrency"
            >
              Save
            </ActionButton>
          </v-card>
        </div>

        <div v-else>{{ item.live }}</div>
      </template>
      
      <template #item.payout="{ item }">
        {{ currency(item.payout) }}
      </template>
      
      <template #item.revenue="{ item }">
        {{ currency(item.revenue) }}
      </template>
      
      <template #item.profit="{ item }">
        {{ currency(item.profit, 'USD', 3) }}
      </template>
  
      <template #item.status="{ item }">
        <SwitchSm
          bgColor="switcherYellow"
          :value="getStatus(item)"
          :disabled="checkVendorPrivate(item)"
          :loading="loadingStatus"
          @input="changeStatus(item)"
        />
      </template>
    
      <template #item.actions="{ item }">
        <TooltipAction bottom message="Edit" v-if="!item.private">
          <template #activator>
            <v-icon class="mr-6" @click="editItem(item[currentGroupPropertyName])">$pencilIcon</v-icon>
          </template>
        </TooltipAction>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getAggregatedGroups, getAggregatedDataByGroups } from '@/api/dashboard-routes'
import { changeCampaignStatus } from '@/api/campaign-routes'
import { changeDestinationStatus, editDestinationForManager } from '@/api/destination-routes'
import { updateVendor as changeVendorStatus } from '@/api/vendor-routes'

import { customSorting } from '@/helper/app-helper'

import SwitchSm from '@/components/controls/SwitchSm'
import InputNumberControl from '@/components/inputs/InputNumberControl'
import ActionButton from '@/components/buttons/ActionButton'
import TooltipAction from '@/components/tooltip/TooltipAction'
import AnimationChangeLive from '@/components/animation/AnimationChangeLive'
import currency from '@/mixins/currency'

export default {
  name: 'DashboardAggregated',
  inheritAttrs: false,
  components: { SwitchSm, InputNumberControl, ActionButton, TooltipAction, AnimationChangeLive },
  mixins: [ currency ],
  props: {
    filters: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    loading: false,
    loadingStatus: false,
    currentGroupIndex: 0,
    currentGroupPropertyName: null,
    aggDataGroups: {},
    aggregatedDataNames: [],
    items: [],
    options: {},
    headers: [],
    defaultHeaders: [
      { text: 'NAME', value: 'name', class: 'pl-18' },
      { text: 'LIVE CALLS', value: 'live', width: 190, align: 'center' },
      { text: 'HOUR', value: 'last_hour', align: 'center' },
      { text: 'CALLS', value: 'total_calls', align: 'center' },
      { text: 'CONVERTED', value: 'converted', align: 'center' },
      { text: 'NOT CONNECTED', value: 'not_connected', align: 'center' },
      { text: 'REVENUE', value: 'revenue', align: 'center' },
      { text: 'PAYOUT', value: 'payout', align: 'center' },
      { text: 'PROFIT', value: 'profit', align: 'center' },
    ],
    statusHeaders: { text: 'STATUS', value: 'status', width: 80, align: 'center', sortable: false },
    actionsHeaders: { text: 'ACTIONS', value: 'actions', sortable: false, width: 120, align: 'right' },
    loadInterval: null,
    selectDestinationForLive: null,
    selectDestinationSlugForLive: null,
    selectMaxConcurrency: 0,
    hideTimeout: null,
    numericSortingTypes: ['Connected', 'Converted', 'Cost', 'Dupe', 'Incoming', 'Live', 'No Connect', 'Payout', 'Profit', 'Qualified', 'Revenue'],
    timeSortingTypes: ['TCL', 'ACL'],
  }),
  computed: {
    ...mapState({
      timezoneId: state => state.auth.timezoneId,
    }),
  },
  mounted() {
    this.loadInterval = setInterval(() => {
      this.loadAggregateData()
    }, 30000)
  },
  methods: {
    getMaxConcurrency(item) {
      if (!!item.destination) {
        return item.destination['max_concurrency']
      }

      return null
    },
    async loadAggregateGroups() {
      this.loading = true
      const { success, payload, message } = await getAggregatedGroups()

      if (success) {
        payload.forEach(group => {
          this.aggDataGroups[group] = []
        })
        this.aggregatedDataNames = payload
        // this.setItems()
        this.loadAggregateData()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        this.loading = false
      }
    },
    async loadAggregateData() {
      this.loading = true
      const currentGroupName = this.aggregatedDataNames[this.currentGroupIndex]
      const { success, payload, message } = await getAggregatedDataByGroups(currentGroupName)
      
      if (success) {
        this.aggDataGroups[currentGroupName] = payload
        this.setItems()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    setItems() {
      this.items = this.aggDataGroups[this.aggregatedDataNames[this.currentGroupIndex]]
      this.currentGroupPropertyName = this.aggregatedDataNames[this.currentGroupIndex].slice(0, -1)
      if (this.currentGroupPropertyName !== 'buyer') {
        this.headers = [
          ...this.defaultHeaders,
          this.statusHeaders,
          this.actionsHeaders
        ]
      } else {
        this.headers = [
          ...this.defaultHeaders,
          this.actionsHeaders
        ]
      }
    },
    activeClass(item) {
      let filterValue
      let filterName = this.currentGroupPropertyName

      switch (filterName) {
        case 'destination':
          filterName = 'target'
          break
        case 'vendor':
          filterName = 'publisher'
          break
      }

      // if (this.currentGroupPropertyName === 'buyer') {
      //   filterValue = item.buyer.slug
      // } else {
        filterValue = item.name
      // }
      if (
        this.filters.hasOwnProperty(filterName) &&
        this.filters[filterName] === filterValue
      ) {
        return 'primary--text'
      }
      return 'grayLighten3--text'
    },
    getStatus(item) {
      return item[this.currentGroupPropertyName]['status_id'] === 1
    },
    async changeStatus(item) {
      this.loadingStatus = true
      
      const { success, payload, message } = await this.getCurrentRouteForChangeStatus(item)
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        
        const groupName = this.aggregatedDataNames[this.currentGroupIndex]
        const index = this.aggDataGroups[groupName].findIndex(groupItem => groupItem === item)
        this.aggDataGroups[groupName][index][this.currentGroupPropertyName]['status_id'] = payload['status_id']
        this.setItems()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingStatus = false
    },
    getCurrentRouteForChangeStatus(item) {
      switch (this.currentGroupPropertyName) {
        case 'campaign':
          return changeCampaignStatus({
            campaignId: item.campaign.slug,
            formData: { status: item.campaign['status_id'] === 1 ? 2 : 1 }
          })
        case 'destination':
          return changeDestinationStatus({
            destinationId: item.destination.slug,
            formData: { status: item.destination['status_id'] === 1 ? 2 : 1 }
          })
        case 'vendor':
          return changeVendorStatus({
            vendorId: item.vendor.slug,
            formData: {
              name: item.vendor.name,
              'status_id': item.vendor['status_id'] === 1 ? 2 : 1,
              'create_numbers': item.vendor['create_numbers'],
              'see_record': item.vendor['see_record']
            }
          })
      }
    },
    checkVendorPrivate(item) {
      if (this.currentGroupPropertyName === 'vendor') {
        return !!item.vendor.private
      } else {
        return false
      }
    },
    editItem(item) {
      switch (this.currentGroupPropertyName) {
        case 'campaign':
          this.$router.push({ name: 'DetailCampaign', params: { slug: item.slug }})
          break;
        case 'destination':
          this.$router.push({ name: 'DetailDestination', params: { slug: item.slug }})
          break;
        case 'vendor':
          this.$router.push({ name: 'DetailVendor', params: { slug: item.slug }})
          break;
        case 'buyer':
          this.$router.push({ name: 'DetailBuyer', params: { slug: item.slug }})
          break;
      }
    },
    changeFilter(item) {
      let filterValue

      let filterName = this.currentGroupPropertyName

      switch (filterName) {
        case 'destination': filterName = 'target'
          break
        case 'vendor': filterName = 'publisher'
          break
      }

      // if (filterName === 'buyer') {
      //   filterValue = item.buyer.slug
      // } else {
        filterValue = item.name
      // }
      if (
        this.filters.hasOwnProperty(filterName) &&
        (this.filters[filterName] === filterValue)
      ) {
        this.clearFilters()
      } else {
        if (filterName === 'vendor') {
          filterName = 'publisher'
        }
        if (filterName === 'destination') {
          filterName = 'target'
        }
        this.$emit('update:filters', { [filterName]: filterValue })
      }
    },
    clearFilters() {
      this.$emit('update:filters', {})
    },
    visibleLiveInput(item) {
      this.selectDestinationSlugForLive = item.destination.slug
      this.selectDestinationForLive = item.destination
      this.selectMaxConcurrency = !!item.destination['max_concurrency']
        ? +item.destination['max_concurrency']
        : 0
    },
    hideLiveInput() {
      this.hideTimeout = setTimeout(() => {
        this.selectDestinationSlugForLive = null
        this.selectDestinationForLive = null
        this.selectMaxConcurrency = 0
      }, 100)
    },
    async saveConcurrency() {
      clearTimeout(this.hideTimeout)
      this.loadingStatus = true
      
      const { success, payload, message } = await editDestinationForManager({
        destinationId: this.selectDestinationSlugForLive,
        formData: {
          ...this.selectDestinationForLive,
          'max_concurrency': this.selectMaxConcurrency ? this.selectMaxConcurrency : null
        }
      })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })

        this.loadAggregateData()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      this.loadingStatus = false
      this.hideLiveInput()
    },
    customSort(items, index, isDesc) {
      if (!index.length) {
        return items
      }
      
      const type = isNaN(items[0][index[0]]) ? 'string' : 'number'
      items.sort((a, b) => {
        return customSorting(a[index[0]], b[index[0]], !!isDesc[0], type)
      })
      return items
    },
  },
  watch: {
    timezoneId: {
      immediate: true,
      handler(id) {
        if (!!id) {
          this.loadAggregateGroups()
        }
      },
    },
    currentGroupIndex: {
      handler() {
        if (this.aggregatedDataNames.length) {
          this.setItems()
        }
      },
    },
    filters: {
      handler(filters) {
        this.$emit('changeFilters', filters)
      },
    },
    selectDestinationSlugForLive: {
      handler() {
        clearTimeout(this.hideTimeout)
      },
    },
  },
  beforeDestroy() {
    clearInterval(this.loadInterval)
  },
}
</script>
