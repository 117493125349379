<template>
  <v-card
    :height="checkXlToXxl ? 100 : 80"
    width="100%"
    class="info-block-container cardChartBg rounded-lg px-6 px-sm-11 pt-6 pt-sm-9"
    outlined
  >
    <v-row no-gutters>
      <v-col :cols="checkXs ? checkXXs ? 8 : 7 : 6" :sm="checkXlToXxl ? 12 : 6">
        <div class="d-block d-xl-flex d-xxl-block justify-md-space-between">
          <div class="font-normal-dashboard statsNameColor--text pb-2">{{ info.name }}</div>
          <div
            class="statsTotalColor--text"
            :class="checkXXs ? 'font-large-alarm pt-4' : 'font-row-title'"
          >{{ info.total }}</div>
        </div>
      </v-col>
      <v-col :cols="checkXs ? checkXXs ? 4 : 5 : 6" :sm="checkXlToXxl ? 12 : 6">
        <div
          class="font-normal-dashboard text-end pr-4"
          :class="[`${info.class}--text`, { 'py-2': checkXlToXxl }]"
        >{{ info.percent }}%</div>
        <v-sparkline
          :value="info.chart"
          :color="info.class"
          stroke-linecap="round"
          :smooth="30"
          :padding="0"
          :height="checkXlToXxl ? 40 : checkXs ? checkXXs ? 180 : 150 : 50"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'InfoBlock',
  inheritAttrs: false,
  props: {
    info: {
      required: true
    }
  },
  data: () => ({
    percent: 0
  }),
  computed: {
    checkXlToXxl() {
      return this.$vuetify.breakpoint.width > 1439 && this.$vuetify.breakpoint.width < 1900
    },
    checkSm() {
      return this.$vuetify.breakpoint.width > 400 && this.$vuetify.breakpoint.width < 600
    },
    checkXs() {
      return this.$vuetify.breakpoint.width < 500
    },
    checkXXs() {
      return this.$vuetify.breakpoint.width < 480
    },
  },
}
</script>

<style lang="scss">
  .v-card.info-block-container {
    border: 1px solid var(--v-infoCardBorder-base)!important;
  }
</style>
