import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getHeaderStats = () => axios.get(`${getAccountId()}/dashboard/header-stats`)
const getChartData = params => axios.get(`${getAccountId()}/dashboard/chart`, { params })
const getAggregatedData = () => axios.get(`${getAccountId()}/dashboard/aggregated`)
const getAggregatedGroups = () => axios.get(`${getAccountId()}/dashboard/aggregated-groups`)
const getAggregatedDataByGroups = group => axios.get(`${getAccountId()}/dashboard/aggregated/${group}`)

export {
  getHeaderStats, getChartData, getAggregatedData, getAggregatedGroups, getAggregatedDataByGroups
}