<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="pb-5 pb-sm-15 d-flex">
      <div class="d-none d-md-flex pt-7 pr-15">
        <portal to="pageTitle" :disabled="checkMd">
          <div class="font-page-header">Dashboard</div>
        </portal>
      </div>
      
      <v-row no-gutters class="ma-n2 ma-sm-n5">
        <v-col
          v-for="(info, index) in dashboardInfo"
          :key="index"
          class="d-flex  pa-2 pa-sm-5"
          :cols="6"
          :md="checkMdToXl ? 4 : 2"
        >
          <InfoBlock :info="info" />
        </v-col>
      </v-row>
    </div>
  
    <HorizontalBarChart
      v-if="!visiblePieChartHideBarChart"
      :data="totalData"
      :isLoading="chartLoading"
    />
    
    <v-row no-gutters>
      <StackBarChart
        class="pb-5 pb-sm-15"
        :data="stackBarData"
        :isLoading="chartLoading"
      />
      
      <v-col cols="12" md="9">
        <DashboardAggregated :filters.sync="filters" />
      </v-col>
  
      <PieChart
        v-if="visiblePieChartHideBarChart"
        :data="totalData"
        :isLoading="chartLoading"
      />
    </v-row>
  </v-container>
</template>

<script>
import InfoBlock from '@/components/dashboard/InfoBlock'
import DashboardAggregated from '@/components/dashboard/DashboardAggregated'

import StackBarChart from '@/components/charts/StackBarChart'
import HorizontalBarChart from '@/components/charts/HorizontalBarChart'
import PieChart from '@/components/charts/PieChart'

import { mapState, mapGetters } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

import { getHeaderStats, getChartData } from '@/api/dashboard-routes'

import currency from '@/mixins/currency'

export default {
  name: 'Dashboard',
  components: { InfoBlock, StackBarChart, HorizontalBarChart, PieChart, DashboardAggregated },
  mixins: [ currency ],
  data: () => ({
    chartLoading: false,
    filters: {},
    dashboardInfo: [{
      id: 'revenue',
      name: 'Revenue',
      total: '$0.00',
      percent: 0,
      chart: [0, 0, 0, 0, 0],
      money: true,
      class: 'dashboardRevenue'
    }, {
      id: 'payout',
      name: 'Payout',
      total: '$0.00',
      percent: 0,
      chart: [0, 0, 0, 0, 0],
      money: true,
      class: 'dashboardPayout'
    }, {
      id: 'incoming',
      name: 'Incoming',
      total: '0',
      percent: 0,
      chart: [0, 0, 0, 0, 0],
      class: 'dashboardIncoming'
    }, {
      id: 'converted',
      name: 'Converted',
      total: '0',
      percent: 0,
      chart: [0, 0, 0, 0, 0],
      class: 'dashboardConverted'
    }, {
      id: 'no_answer',
      name: 'No Answer',
      total: '0',
      percent: 0,
      chart: [0, 0, 0, 0, 0],
      class: 'dashboardNoAnswer'
    }, {
      id: 'acl',
      name: 'ACL',
      total: '$0.00',
      percent: 0,
      chart: [0, 0, 0, 0, 0],
      class: 'dashboardACL'
    }],
    chartData: [],
    details: {
      noAnswer: [],
      converted: [],
      notConverted: [],
    },
    date: 'defaultDate()',
    loadInterval: null,
  }),
  computed: {
    ...mapState({
      timezoneId: state => state.auth.timezoneId,
    }),
    ...mapGetters({
      isManager: authTypes.getters.IS_MANAGER,
    }),
    stackBarData() {
      return this.chartData.map(item => ({
        ...item,
        'not_converted': item.total - item.converted - item['no_answer']
      }))
    },
    visiblePieChartHideBarChart() {
      return this.$vuetify.breakpoint.width > 1012
    },
    allConvertedCalls() {
      return this.details.converted.reduce((value, item) => value + item, 0)
    },
    allNoConvertedCalls() {
      return this.details.notConverted.reduce((value, item) => value + item, 0)
    },
    allNoAnswerCalls() {
      return this.details.noAnswer.reduce((value, item) => value + item, 0)
    },
    totalData() {
      return [{
        name: 'Converted',
        value: this.allConvertedCalls
      }, {
        name: 'Not Converted',
        value: this.allNoConvertedCalls
      }, {
        name: 'No Answer',
        value: this.allNoAnswerCalls
      }]
    },
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkMdToXl() {
      return this.$vuetify.breakpoint.width > 1029 && this.$vuetify.breakpoint.width < 1440
    },
  },
  mounted() {
    this.loadInterval = setInterval(() => {
      if (this.isManager) {
        this.loadDashboardData()
      }
    }, 30000)
  },
  methods: {
    loadDashboardData() {
      this.loadHeaderStats()
      this.loadChartData()
    },
    async loadHeaderStats() {
      const { success, payload, message } = await getHeaderStats()
      
      if (success) {
        this.setInfoData(payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async loadChartData() {
      this.chartLoading = true
      
      const { success, payload, message } = await getChartData(this.filters)
      
      if (success) {
        this.chartData = Array.isArray(payload) ? payload : []
        this.setChartDataDetails(this.chartData)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      this.chartLoading = false
    },
    setChartDataDetails(chartData) {
      if (chartData.length) {
        this.clearData()
        this.details = chartData.reduce((data, item) => {
          data.converted.push(item.converted)
          data.noAnswer.push(item['no_answer'])
          data.notConverted.push(item.total - item.converted - item['no_answer'])
          return data
        }, {
          noAnswer: [],
          converted: [],
          notConverted: [],
        })
      }
    },
    clearData() {
      Object.keys(this.details).forEach(name => {
        this.details[name] = []
      })
    },
    setInfoData(stats) {
      if (stats) {
        this.dashboardInfo.forEach(item => {
          item.total = item.money
            ? this.currency(stats[item.id].total)
            : stats[item.id].total
          item.percent = stats[item.id].percent
          item.chart = Object.values(stats[item.id].chart).map(n => +n)
        })
      }
    },
  },
  watch: {
    timezoneId: {
      immediate: true,
      handler(id) {
        if (!!id) {
          this.loadDashboardData()
        }
      },
    },
    filters: {
      handler() {
        this.loadDashboardData()
      },
    },
  },
  beforeDestroy() {
    clearInterval(this.loadInterval)
  },
}
</script>
